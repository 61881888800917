/* eslint-disable camelcase */
import React from 'react';
import {
    AllFieldsType,
    ArrayFieldSchema,
    ConditionalType,
    ConditionalValidation,
    FieldStructure,
    FormSchema,
    InputFieldStructure,
} from '@mercell/form-react';
import {
    ConditionItem,
    ConditionLogicalExpression,
    DefinitionElementType,
    FormDefinitionItem,
    UIDesignElementType,
} from '../types/generated/formDefinitionItem';
import { getVisibilityValue } from './getVisibilityValue';
import * as yup from 'yup';
import { getRulesSetup } from './getRulesSetup';
import { getStyleClasses } from './getStyleClasses';
import { Add32 } from '@carbon/icons-react';
import { Button } from '@mercell/button-react';
import { parseDefinitionType } from './parseDefinitionType';
import { Guid } from './Guid';
import { mapNutsSelectorField } from './fields/nutsSelector';
import { mapCPVSelectorField } from './fields/cpvSelector';
import { mapTextareaField } from './fields/textarea';
import { mapNumberField } from './fields/number';
import { mapButtonField } from './fields/button';
import { mapRadioField } from './fields/radio';
import { mapCheckboxField } from './fields/checkbox';
import { TFunction } from 'react-i18next';
import { mapInputWithDropdownField } from './fields/inputWithDropdown';
import { mapDatepickerField } from './fields/datepicker';
import { mapTimepickerField } from './fields/timepicker';
import { mapSelectField } from './fields/select';
import { mapChangeField } from './fields/change';
import { mapInformationBoxField } from './fields/informationBox';
import { mapReadOnlyField } from './fields/readonly';
import { mapLabel } from './fields/label';
import { getArrayFieldLabel } from './fields/array';
import { AnySchema } from 'yup';
import i18next from 'i18next';
import { DateFns } from './dateFnsFormats';
import { mapAlertField } from './fields/alert';

const parseFormDefinitionWrapper = (
    definition: FormDefinitionItem[],
    t: TFunction<string, unknown>,
    isFormPreview: boolean,
    dateFormat: DateFns,
    showPreview?: boolean,
    locale?: Locale,
    featureFlagUseUtcDatesOnly?: boolean,
    noticeTimeZone?: string,
    featureFlagEnableTextAreaCounter?: boolean
): FormSchema => {
    const schema: FormSchema = {};
    definition
        .filter(
            (obj) =>
                (obj.designElementType === UIDesignElementType.Preview &&
                    showPreview) ||
                (obj.designElementType !== UIDesignElementType.Preview &&
                    !showPreview)
        )
        // Loop through the sections (e.g: ObjectSection, ProcedureSection)
        .forEach((obj) => {
            schema[obj.name] = parseFormDefinition(
                obj,
                t,
                isFormPreview,
                dateFormat,
                locale,
                noticeTimeZone,
                undefined,
                featureFlagUseUtcDatesOnly,
                featureFlagEnableTextAreaCounter
            );
        });
    return schema;
};

const parseFormDefinition = (
    obj: FormDefinitionItem,
    t: TFunction<string, unknown>,
    isFormPreview: boolean,
    dateFormat: DateFns,
    locale?: Locale,
    noticeTimeZone?: string,
    isAccordionContent = false,
    featureFlagUseUtcDatesOnly = false,
    featureFlagEnableTextAreaCounter = false
): AllFieldsType => {
    const { divStyle, elementStyle, labelStyle, sectionStyle } =
        getStyleClasses(obj, isAccordionContent);
    const { language } = i18next;
    const tempFieldStructure: FieldStructure = {
        fieldLabel: mapLabel(obj, labelStyle, isFormPreview, t),
        placeholder: obj.placeholder,
        fieldWrapperProperties: {
            className: divStyle,
        },
        elementProperties: {
            className: elementStyle,
            'data-test': obj.name,
            disabled: obj.disabled,
        },
    };
    if (obj.conditions !== undefined && obj.conditions.length > 0) {
        tempFieldStructure.conditionalRender = {
            conditionalFields: obj.conditions.map((condition) => ({
                fieldName: condition.fieldName || '',
                comparisonMethod: (value: any) =>
                    getVisibilityValue(condition, value, condition.fieldName),
                useSamePath: condition.useSamePath,
            })),
        };
        if (obj.isDisjunctionConditionList)
            tempFieldStructure.conditionalRender.conditionalRulesChainOption =
                'OR';
    }

    if (
        obj.conditionalVisibility?.logicalExpression?.conditions &&
        obj.conditionalVisibility?.logicalExpression?.conditions.length > 0
    ) {
        const conditions = transformCondition(
            obj.conditionalVisibility.logicalExpression
        ) as ConditionalValidation;

        if (conditions !== undefined) {
            tempFieldStructure.conditionalRender = {
                associatedVisibilityBasedOnOtherFieldValues: {
                    conditionalFields: conditions,
                },
            };
        }
    }

    if (
        obj.conditionalValidation?.logicalExpression?.conditions &&
        obj.conditionalValidation?.logicalExpression?.conditions.length > 0
    ) {
        const conditions = transformCondition(
            obj.conditionalValidation.logicalExpression
        ) as ConditionalValidation;

        let reApplyRequiredIndicator = false;
        if (
            obj.conditionalValidation.rules &&
            obj.conditionalValidation.rules.length > 0
        ) {
            reApplyRequiredIndicator = obj.conditionalValidation.rules?.some(
                (rule) => rule.function === 'required'
            );
        }

        if (conditions !== undefined) {
            tempFieldStructure.associatedValidationBasedOnOtherFieldValues = {
                conditionalFields: conditions,
                message: obj.conditionalValidation.message,
                rules: undefined,
                reApplyRequiredIndicator,
            };
        }
    }

    if (obj.revalidateFields !== undefined && obj.revalidateFields.length > 0) {
        tempFieldStructure.arrayOfFieldsToRevalidateOnChange =
            obj.revalidateFields;
    }

    let validations: AnySchema | undefined;
    const objType =
        DefinitionElementType[obj.type][0].toLowerCase() +
        DefinitionElementType[obj.type].slice(1);

    let fieldStructure: AllFieldsType = {
        ...tempFieldStructure,
        type: objType as Omit<AllFieldsType, 'InputFieldStructure'>['type'] &
            InputFieldStructure['type'],
    };

    if (
        (obj.preview || obj.showAsLabel) &&
        obj.type !== DefinitionElementType.Hidden &&
        obj.type !== DefinitionElementType.Checkbox &&
        obj.type !== DefinitionElementType.Change
    ) {
        fieldStructure = {
            ...tempFieldStructure,
            type: 'readOnly',
            isSortable: obj.isSortable,
            isMultiSelect: obj.isMultiSelect,
            fieldOrigin: parseDefinitionType(obj.type),
            showAsLabel: obj.showAsLabel,
            translationFileName: obj.translationFileName,
            options: obj.options,
            isTranslatable: obj.isTranslatable,
            showWithTime: obj.type === DefinitionElementType.Time,
        };
        return fieldStructure;
    }

    switch (obj.type) {
        case DefinitionElementType.Alert:
            return mapAlertField(obj, tempFieldStructure, t, locale);
        case DefinitionElementType.Array:
            if (obj.fields) {
                const arrayFieldsSchema: ArrayFieldSchema = {};
                obj.fields.forEach((field) => {
                    arrayFieldsSchema[field.name] = parseFormDefinition(
                        field,
                        t,
                        isFormPreview,
                        dateFormat,
                        locale,
                        noticeTimeZone,
                        isAccordionContent,
                        featureFlagUseUtcDatesOnly,
                        featureFlagEnableTextAreaCounter
                    );
                });
                if (obj.rules) {
                    validations = getRulesSetup(
                        yup.array().ensure(),
                        obj.rules,
                        t,
                        obj.businessTerm
                    );
                }
                if (
                    tempFieldStructure.associatedValidationBasedOnOtherFieldValues
                ) {
                    if (
                        obj.conditionalValidation?.rules &&
                        tempFieldStructure.associatedValidationBasedOnOtherFieldValues
                    ) {
                        const conditionalValidations = getRulesSetup(
                            yup.array().ensure(),
                            obj.conditionalValidation.rules,
                            t,
                            obj.businessTerm
                        );
                        tempFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                            conditionalValidations;
                    }
                }
                fieldStructure = {
                    ...tempFieldStructure,
                    validations: isFormPreview ? undefined : validations,
                    type: 'array',
                    defaultAppendValue: () => ({
                        [`${obj.name}UniqueId`]: Guid.newGuid(),
                    }),
                    arrayFields: arrayFieldsSchema,
                    hideAppendButton: obj.isArrayLocked || obj.isAddingDisabled, // <-- use =true for making array not appendable
                    // fields count can be used as counter that you want to add e.g. 4th field so it will count already added array fields and give back the current number
                    appendButton: (action: () => void) => (
                        <Button
                            scheme="secondary"
                            className="mt-8 ml-14"
                            type="button"
                            iconSettings={{
                                Icon: Add32,
                            }}
                            onClick={action}
                            data-test="append-button"
                        >
                            {t(obj.labelAppend ?? 'form-content:LabelAppend')}
                        </Button>
                    ),
                    errorsWrapperProperties: { className: 'ml-14' },
                    // index can be used to show the counter near button
                    // removeButton: (action, index) => (
                    // <IconButton
                    //     className="tertiary absolute top-0 right-0"
                    //     Icon={TrashCan32}
                    //     type="button"
                    //     onClick={action}
                    // >
                    //     {t(
                    //         obj.labelRemove ??
                    //             'form-content:LabelRemove'
                    //     )}
                    // </IconButton>
                    // ),
                    // index can be used to show the counter near new array field label if necessary, also there is a name if necessary
                    arrayFieldLabel: getArrayFieldLabel(t, obj, isFormPreview),
                    // this is a new wrapper which contain the new label inside array field
                    internalFieldsAndLabelWrapper: {},
                    // this is the old wrapper, renamed to arrayFieldsWrapperProperties and it contains only the array fields without the array label
                    internalFieldsWrapper: {
                        className: obj.elementStyle,
                    },
                    // new property for hiding globally the append button - instead of isArrayLocked
                    //  hideAppendButton: obj.isArrayLocked,
                };
            }
            break;
        case DefinitionElementType.Section:
            fieldStructure = {
                ...tempFieldStructure,
                type: 'section',
                sectionProperties: { className: sectionStyle },
                fields:
                    obj.fields?.map((field) => ({
                        [field.name]: parseFormDefinition(
                            field,
                            t,
                            isFormPreview,
                            dateFormat,
                            locale,
                            noticeTimeZone,
                            isAccordionContent ||
                                obj.designElementType ===
                                    UIDesignElementType.Preview,
                            featureFlagUseUtcDatesOnly,
                            featureFlagEnableTextAreaCounter
                        ),
                    })) || [],
            };

            break;
        case DefinitionElementType.Datepicker:
            return mapDatepickerField(
                obj,
                tempFieldStructure,
                t,
                dateFormat,
                locale,
                undefined,
                featureFlagUseUtcDatesOnly
            );
        case DefinitionElementType.Time:
            return mapTimepickerField(
                obj,
                tempFieldStructure,
                t,
                dateFormat,
                locale,
                noticeTimeZone,
                featureFlagUseUtcDatesOnly
            );
        case DefinitionElementType.ReadOnly:
            return mapReadOnlyField(
                obj,
                tempFieldStructure,
                t,
                isFormPreview,
                locale
            );
        case DefinitionElementType.Select:
            return mapSelectField(obj, tempFieldStructure, t);
        case DefinitionElementType.InputWithDropdown:
            return mapInputWithDropdownField(obj, tempFieldStructure, t);
        case DefinitionElementType.Radio:
            return mapRadioField(obj, tempFieldStructure, t);
        case DefinitionElementType.Checkbox:
            return mapCheckboxField(obj, tempFieldStructure, t, isFormPreview);
        case DefinitionElementType.Button:
            return mapButtonField(obj, tempFieldStructure, t);
        case DefinitionElementType.Number:
            return mapNumberField(obj, tempFieldStructure, t);
        case DefinitionElementType.TextArea:
            return mapTextareaField(
                obj,
                tempFieldStructure,
                t,

                isFormPreview,
                featureFlagEnableTextAreaCounter
            );
        case DefinitionElementType.NutsSelector:
            return mapNutsSelectorField(
                obj,
                tempFieldStructure,
                t,
                false // Allow selection of countries in NutsMultiSelect. Currently this element type is only used in UK
            );
        case DefinitionElementType.CpvSelector:
            return mapCPVSelectorField(obj, tempFieldStructure, language, t);
        case DefinitionElementType.Change:
            return mapChangeField(
                obj,
                tempFieldStructure,
                locale,
                noticeTimeZone
            );
        case DefinitionElementType.InformationBox:
            return mapInformationBoxField(obj, tempFieldStructure);
        default:
            if (obj.rules) {
                validations = getRulesSetup(
                    yup.string().nullable(),
                    obj.rules,
                    t,
                    obj.businessTerm
                );
                fieldStructure = {
                    ...fieldStructure,
                    validations,
                };
            }
            if (obj.conditionalValidation?.rules) {
                const conditionalValidations = getRulesSetup(
                    yup.string().nullable(),
                    obj.conditionalValidation.rules,
                    t,
                    obj.businessTerm
                );
                if (
                    fieldStructure.associatedValidationBasedOnOtherFieldValues
                ) {
                    fieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                        conditionalValidations;
                }
            }
            break;
    }
    return fieldStructure;
};

function isConditionItem(
    item: ConditionItem | ConditionLogicalExpression
): item is ConditionItem {
    return (item as ConditionItem).fieldName !== undefined;
}

function transformCondition(
    input: ConditionLogicalExpression
): ConditionalValidation | ConditionalType {
    if (isConditionItem(input)) {
        return {
            fieldName: input.fieldName,
            useSamePath: input.useSamePath,
            comparisonMethod: (value: any, fieldName?: string) =>
                getVisibilityValue(input, value, fieldName),
        } as ConditionalType;
    }

    return {
        logicalOperator: input.logicalOperator,
        conditions: input.conditions?.map(transformCondition),
    };
}

export default parseFormDefinitionWrapper;
