/* eslint-disable no-debugger */
import './i18n';

import React, {
    RefObject,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import initAxios from './shared/utilities/http';
import {
    Home32,
    RequestQuote32,
    Table32,
    Translate32,
    Information20,
    Dashboard32,
    ColorSwitch32,
    Tools32,
    User32,
    Search32,
} from '@carbon/icons-react';
import {
    GlobalMenu,
    Sidebar,
    SidebarContainer,
    UserMenu,
} from '@mercell/global-menu-react';
import { Ribbon } from '@mercell/ribbon-react';
import { useStoreContext } from './state';
import roles from './state/roles';
import cx from 'classnames';
import { LanguageSelector } from '@mercell/language-selector-react';
import i18n from 'i18next';
import TranslationVisibilityToggle from './components/TranslationVisibilityToggle';

import countries from './components/LanguageSelector/countries';

import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mercell/tooltip-react';

import { FormStatus } from './types/generated/formMenuResult';
import { AutoSaveStatusIndicator } from './components/AutoSaveStatusIndicator';
import { FunctionScheme } from '@mercell/tailwind';
import useFetchUserRights from './hooks/useFetchUserRights';

import { useAppDispatch, useAppSelector } from './redux/hooks';
import { resetAutoSave, setLocale, setUserRights } from './redux/storeSlice';
import { delay } from './shared/utilities/delay';
import { useOktaAuth } from '@okta/okta-react';

const logoProps = {
    width: '294px',
    height: '26px',
};

async function fetchLocaleIfExists(language: string): Promise<Locale | null> {
    try {
        if (language === 'en') {
            return (await import(`date-fns/locale/en-GB/index.js`)) as Locale;
        }
        return await import(`date-fns/locale/${language}/index.js`);
    } catch (err) {
        console.warn(err);
        return null;
    }
}

const debounce = (
    fn: (headerRef: RefObject<HTMLDivElement>) => void,
    headerRef: RefObject<HTMLDivElement>
) => {
    let frame: any;
    return () => {
        if (frame) {
            cancelAnimationFrame(frame);
        }
        frame = requestAnimationFrame(() => {
            fn(headerRef);
        });
    };
};

const storeScroll = (headerRef: RefObject<HTMLDivElement>) => {
    const element = headerRef.current;
    if (!element) return;
    if (window.scrollY > 0) {
        element.dataset.scroll = 'true';
    } else {
        element.dataset.scroll = 'false';
    }
};

export const Header = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const [showMenus, setShowMenus] = useState(false);
    const i18nextLng = sessionStorage.getItem('i18nextLng');
    const [language, setLanguage] = useState<string>(
        i18nextLng ?? 'en-GB'
    );
    const { oktaAuth } = useOktaAuth();
    const headerRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation(['form-content', 'toast-content']);
    initAxios();

    const { UserRights } = useFetchUserRights();

    const sidebarData = {
        home: {
            title: t('form-content:Home'),
            Icon: Home32,
            path: `/`,
        },
        newForm: {
            title: t('form-content:NewForm'),
            Icon: RequestQuote32,
            path: `/create-form`,
        },
    };

    if (!!UserRights && UserRights.configuratorEnabled) {
        Object.assign(sidebarData, {
            configurator: {
                title: t('form-content:FormConfigurator'),
                Icon: Table32,
                path: `/configurator`,
            },
        });
    }

    if (!!UserRights && UserRights.translationsReviewEnabled) {
        Object.assign(sidebarData, {
            translations: {
                title: t('form-content:TranslationsReview'),
                Icon: Translate32,
                path: `/translations/review`,
            },
        });
    }

    if (!!UserRights && UserRights.dashboardEnabled) {
        Object.assign(sidebarData, {
            dashboard: {
                title: t('form-content:Dashboard'),
                Icon: Dashboard32,
                path: `/dashboard`,
            },
        });
    }

    if (!!UserRights && UserRights.logsExplorerEnabled) {
        Object.assign(sidebarData, {
            logsExplorer: {
                title: 'Logs Explorer',
                Icon: Search32,
                path: `/logs`,
            },
        });
    }

    if (!!UserRights && UserRights.featuresReviewEnabled) {
        Object.assign(sidebarData, {
            featureflags: {
                title: 'Feature flags',
                Icon: ColorSwitch32,
                path: `/featureflags/list`,
            },
        });
    } // Apply user rights here
    if (!!UserRights && UserRights.publicationConfiguratorEnabled) {
        Object.assign(sidebarData, {
            publicationSiteConfigurations: {
                title: 'Publication Configurator',
                Icon: Tools32,
                path: `/publicationsite/configurator`,
            },
        });
    }

    if (!!UserRights && UserRights.userManagementEnabled) {
        Object.assign(sidebarData, {
            userManagement: {
                title: 'User Management',
                Icon: User32,
                path: `/user-management`,
            },
        });
    }

    const changeLanguage = (lngCode: string) => {
        setLanguage(lngCode);
        i18n?.changeLanguage(lngCode);
    };

    const {
        state: { userRole, autoSave, UserRights: stateUserRights },
        dispatch,
    } = useStoreContext();

    const reduxAutoSave = useAppSelector((state) => state.store.autoSave);
    const reduxUserRole = useAppSelector((state) => state.store.userRole);
    const reduxUserName = useAppSelector((state) => state.store.userName);
    const reduxTenderName = useAppSelector((state) => state.store.tenderName);
    const reduxNoticeStatus = useAppSelector(
        (state) => state.store.noticeStatus
    );
    const reduxUserRights = useAppSelector((state) => state.store.userRights);

    const reduxDispatch = useAppDispatch();

    useEffect(() => {
        if (UserRights && reduxUserRights !== UserRights) {
            reduxDispatch(setUserRights(UserRights ?? {}));
        }
    }, [dispatch, stateUserRights, UserRights, reduxUserRights, reduxDispatch]);

    const onLogout = async () => {
        oktaAuth.signOut();
    };

    const importLocaleForDatePicker = useCallback(async () => {
        let locale = await fetchLocaleIfExists(language);
        if (!locale && language.includes('-')) {
            locale = await fetchLocaleIfExists(language.split('-')[0]);
        }
        if (locale) {
            reduxDispatch(setLocale(locale));
        }
    }, [language, reduxDispatch]);

    useEffect(() => {
        document.addEventListener('scroll', debounce(storeScroll, headerRef), {
            passive: true,
        });
        storeScroll(headerRef);

        return () => {
            document.removeEventListener(
                'scroll',
                debounce(storeScroll, headerRef)
            );
        };
    }, []);
    useEffect(() => {
        if (
            reduxUserRole === roles.admin &&
            !window.location.href.includes('validation/')
        )
            setShowMenus(true);
    }, [reduxUserRole, userRole]);

    useEffect(() => {
        importLocaleForDatePicker();
    }, [importLocaleForDatePicker, language]);

    useEffect(() => {
        async function resetSave() {
            await delay(2000);
            if (reduxAutoSave.isSaved) {
                reduxDispatch(resetAutoSave());
            }
        }
        resetSave();
    }, [autoSave.isSaved, dispatch, reduxAutoSave.isSaved, reduxDispatch]);

    const noticeStatusValue = reduxNoticeStatus?.value;
    let statusColor: FunctionScheme;

    if (
        noticeStatusValue === FormStatus.Error ||
        noticeStatusValue === FormStatus.Rejected ||
        noticeStatusValue === FormStatus.Deleted
    ) {
        statusColor = 'error';
    } else if (
        noticeStatusValue === FormStatus.Published ||
        noticeStatusValue === FormStatus.PublishedTed ||
        noticeStatusValue === FormStatus.PublishedNational
    ) {
        statusColor = 'success';
    } else if (noticeStatusValue === FormStatus.Draft) {
        statusColor = 'default';
    } else {
        statusColor = 'information';
    }

    const getLogoProps = () => {
        let globalMenuLogoProps;
        if (window.location.href.includes('dashboard')) {
            globalMenuLogoProps = {
                src: '/images/dashboard-logo.jfif',
                alt: 'Dashboard Mercell logo',
                ...logoProps,
                height: '40px',
            };
        }
        if (window.location.href.includes('configurator')) {
            globalMenuLogoProps = {
                src: '/images/form-configurator.svg',
                alt: 'Form Configurator logo',
                ...logoProps,
                height: '40px',
            };
        }
        return globalMenuLogoProps;
    };

    return (
        <>
            {reduxUserRole === roles.rep &&
                !window.location.href.includes('validation') &&
                !window.location.href.includes('downloadxml') &&
                !window.location.href.includes('json') &&
                !window.location.href.includes('print') && (
                    <div
                        ref={headerRef}
                        className={cx(
                            `row-start-1 grid grid-cols-2 grid-rows-2 gap-4 pt-2 px-4 col-span-full items-center sticky top-0 bg-white z-[1000] data-[scroll=true]:shadow-[0px_1px_4px_rgba(114,114,114,0.15)]`
                        )}
                    >
                        <img
                            {...logoProps}
                            src="/images/notice-editor.svg"
                            alt="Mercell logo"
                            className={cx(
                                'row-start-1 col-start-1',
                                window.location !== window.parent.location
                                    ? 'mt-3'
                                    : 'ml-4 hidden md:block'
                            )}
                        />
                        <div className="row-start-1 col-start-2 flex justify-end">
                            <Tooltip
                                className="truncate xl:max-w-[50%]"
                                message={reduxTenderName}
                                placement="bottom"
                                tooltipInnerWrapperClassName="mr-4"
                            >
                                <p className="truncate mr-2">
                                    {reduxTenderName}
                                </p>
                            </Tooltip>
                        </div>
                        {reduxNoticeStatus && reduxNoticeStatus.status && (
                            <div className="row-start-2 col-start-2 ml-auto mr-1 mb-3 flex flex-col">
                                <div className="flex w-fit h-fit items-center">
                                    {t('form-content:NoticeStatus')}
                                    <Tooltip
                                        message={t(
                                            'form-content:NoticeStatusTooltip'
                                        )}
                                        placement="bottom"
                                        tooltipInnerWrapperClassName="mr-4"
                                    >
                                        <Information20 className="ml-[8.5px] mr-[9.5px]" />
                                    </Tooltip>
                                    <Ribbon scheme={statusColor}>
                                        {reduxNoticeStatus?.status}
                                    </Ribbon>
                                </div>
                            </div>
                        )}
                        <div className="row-start-2 col-start-1">
                            <div className="pl-4 flex items-center">
                                <AutoSaveStatusIndicator
                                    autoSave={reduxAutoSave}
                                    t={t}
                                />
                            </div>
                        </div>
                    </div>
                )}
            {(window.location.href.includes('validationSchematron') ||
                window.location.href.includes('validationQuick') ||
                window.location.href.includes('validationFull')) && (
                <div className="flex items-center col-span-full align-baseline">
                    <img
                        {...logoProps}
                        src="https://cdn.mercell.com/logos/Mercell_Logo_rgb_dark.svg"
                        alt="Mercell logo"
                        className="ml-4 hidden sm:block p-6 "
                    />
                    <h1 className="text-brand-dark">
                        {t('form-content:ValidationErrors')}
                    </h1>
                </div>
            )}
            {window.location.href.includes('downloadxml') && (
                <div className="flex items-center col-span-full align-baseline">
                    <img
                        {...logoProps}
                        src="https://cdn.mercell.com/logos/Mercell_Logo_rgb_dark.svg"
                        alt="Mercell logo"
                        className="ml-4 hidden sm:block p-6 "
                    />
                    <h1 className="text-brand-dark">
                        {t('form-content:DownloadXml')}
                    </h1>
                </div>
            )}
            {window.location.href.includes('auditlog') && (
                <div className="flex items-center col-span-full align-baseline">
                    <img
                        {...logoProps}
                        src="https://cdn.mercell.com/logos/Mercell_Logo_rgb_dark.svg"
                        alt="Mercell logo"
                        className="ml-4 hidden sm:block p-6 "
                    />
                    <h1 className="text-brand-dark">
                        {t('form-content:AuditLog')}
                    </h1>
                </div>
            )}
            {window.location.href.includes('publicationtask') && (
                <div className="flex items-center col-span-full align-baseline">
                    <img
                        {...logoProps}
                        src="https://cdn.mercell.com/logos/Mercell_Logo_rgb_dark.svg"
                        alt="Mercell logo"
                        className="ml-4 hidden sm:block p-6 "
                    />
                    <h1 className="text-brand-dark">
                        {t('form-content:PublicationTaskContent')}
                    </h1>
                </div>
            )}
            {window.location.href.includes('previewhtml') && (
                <div className="flex items-center col-span-full align-baseline">
                    <img
                        {...logoProps}
                        src="https://cdn.mercell.com/logos/Mercell_Logo_rgb_dark.svg"
                        alt="Mercell logo"
                        className="ml-4 hidden sm:block p-6 "
                    />
                    <h1 className="text-brand-dark">
                        {t('form-content:PreviewHtml')}
                    </h1>
                </div>
            )}
            {showMenus && (
                <div className="z-40 top-0 sticky">
                    <GlobalMenu
                        logoPath="/"
                        isAuthenticated
                        openSidebarOnMobile={setShowSidebar}
                        logoProps={getLogoProps()}
                    >
                        <TranslationVisibilityToggle />
                        <LanguageSelector
                            id="language-selector"
                            label="Language selector"
                            countries={countries}
                            localeLng={language}
                            onClick={changeLanguage}
                        />
                        <UserMenu
                            profileText="My profile (not implemented yet)"
                            loginText={t('form-content:LogIn')}
                            logoutText={t('form-content:LogOut')}
                            isAuthenticated
                            userNickname={reduxUserName ?? 'User'}
                            login={async () => {
                                await console.log('login');
                            }}
                            logout={onLogout}
                        />
                    </GlobalMenu>
                </div>
            )}
            {showMenus && (
                <SidebarContainer>
                    <Sidebar
                        logoPath="/"
                        navigationData={sidebarData}
                        showSidebar={showSidebar}
                        openSidebarOnMobile={setShowSidebar}
                    />
                </SidebarContainer>
            )}
        </>
    );
};
