import { FormDefinitionItem } from '../../types/generated/formDefinitionItem';
import { getRulesSetup } from '../getRulesSetup';
import * as yup from 'yup';
import {
    FieldStructure,
    NutsSelectorFieldStructure,
} from '@mercell/form-react';
import { TFunction } from 'react-i18next';

export const mapNutsSelectorField = (
    formDefinition: FormDefinitionItem,
    genericFieldStructure: FieldStructure,
    t: TFunction<string, unknown>,
    allowSelectionOfLevel1and2Locations: boolean
): NutsSelectorFieldStructure => {
    if (genericFieldStructure.associatedValidationBasedOnOtherFieldValues) {
        if (
            formDefinition.conditionalValidation?.rules &&
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues
        ) {
            const conditionalValidations = getRulesSetup(
                yup.string().nullable(),
                formDefinition.conditionalValidation.rules,
                t
            );
            genericFieldStructure.associatedValidationBasedOnOtherFieldValues.rules =
                conditionalValidations;
        }
    }
    return {
        ...genericFieldStructure,
        type: 'nuts-selector',
        isClearable: true,
        disableAllButNUTS3: allowSelectionOfLevel1and2Locations,
        selfDisableComponentIfNoValues: true,
        isMulti: formDefinition.isMultiSelect ?? false,
        fieldToTrack: {
            fieldName: formDefinition.parentField?.fieldName ?? '',
            useSamePath: formDefinition.parentField?.useSamePath,
        },
        disableValidationIfNoNuts: true,
        validations: formDefinition.rules
            ? getRulesSetup(yup.string().nullable(), formDefinition.rules, t)
            : undefined,
    };
};
